
import { Component, Prop, Vue } from "vue-property-decorator";
import { dispatchSubmitWithdrawRequest } from "@/store/mlm/actions";
import { dispatchGetUserProfile } from "@/store/main/actions";
import { dispatchValidateDexnetAddress } from "@/store/mlm/actions";
import { readAppConfigs, readUserProfile } from "@/store/main/getters";

@Component({
  components: {},
})
export default class WithdrawalModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  public standart: string = "BEP20";
  public token: string = "USDT";
  public withdrawStep = 0;
  public withdrawAmount: any = 0.0;
  public withdrawConvertedAmount: any = 0.0;
  public withdrawFee: any = 0.0;
  public withdrawAddress = "";
  public withdrawSecurityCode = "";
  public withdrawAddressError: any = "";
  public withdrawAmountError: any = "";
  public withdrawSecurityCodeError: any = "";
  public secondsLeft = 180;
  public withdrawRequestUpdate = 10;
  public withdrawRequestId = null;
  public minDexnetWithdrawAmount = 5;
  // public withdrawRequestInfo: IWithdrawRequestInfo = {
  //   id: 0,
  //   paid_status: '',
  //   confirmed_by_email: false,
  //   confirmed_by_telegram: false,
  // };
  public isModalClosed: boolean = false;
  public loadingState: any = {
    submit: false,
  };

  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    // if (this.userProfile) {
    //   this.userMailLink = this.userProfile.email.split('@')[1];
    // }
    // if (this.userProfile && !this.userProfile.tg_bot_connected) {
    //   this.withdrawStep = 3;
    // } else {
    this.withdrawStep = 1;
    // }
  }

  public calcWithdrawFee() {
    if (!this.withdrawAmount || Number(this.withdrawAmount) === 0) {
      return 0;
    }
    const minFee = Number(
      this.appConfigs.withdrawal_min_fee
        ? this.appConfigs.withdrawal_min_fee
        : 1
    );
    const fee = Number(
      Number(this.withdrawAmount) *
        (Number(
          this.token === "USDT" ? this.appConfigs.withdrawal_fee_percent : 1
        ) /
          100)
    ).toFixed(8);
    return Number(fee) > Number(minFee) ? fee : minFee;
  }

  public amountChange() {
    this.withdrawFee = this.calcWithdrawFee();
    let minAmount =
      this.token === "USDT"
        ? Number(this.appConfigs.min_amount_for_withdraw_usd)
        : this.minDexnetWithdrawAmount;
    if (Number(this.withdrawAmount) >= Number(minAmount)) {
      this.withdrawConvertedAmount =
        Number(this.withdrawAmount) - Number(this.withdrawFee);
    } else {
      this.withdrawConvertedAmount = 0;
    }
  }

  public async validateWithdrawForm() {
    if (
      this.token === "USDT" &&
      this.userProfile &&
      this.userProfile.balances.units < this.withdrawAmount
    ) {
      this.withdrawAmountError = this.$t(
        "Amount is bigger than your available balance"
      );
      return false;
    }
    if (
      this.token === "DEXNET" &&
      this.userProfile &&
      this.userProfile.balances.dnt < this.withdrawAmount
    ) {
      this.withdrawAmountError = this.$t(
        "Amount is bigger than your available balance"
      );
      return false;
    }
    // if (!this.userProfile || !this.userProfile.tg_bot_connected) {
    //   commitSetUserErrorAlert(this.$store, {show: true, text: this.$t('Connect telegram to your account in Profile to withdraw funds').toString()});
    //   setTimeout( async  () => {
    //     commitSetUserErrorAlert(this.$store, {show: false, text: ''});
    //   }, 4000);
    //   return false;
    // }

    this.withdrawAmountError =
      Number(this.withdrawAmount) <= 0
        ? this.$t("Amount must be more than zero")
        : "";
    this.withdrawAmountError =
      Number(this.withdrawAmount) < this.minDexnetWithdrawAmount &&
      this.token === "DEXNET"
        ? this.$t(`Minimum amount is ${this.minDexnetWithdrawAmount} DNT`)
        : this.withdrawAmountError;

    this.withdrawAmountError =
      Number(this.withdrawAmount) <
        this.appConfigs.min_amount_for_withdraw_usd && this.token === "USDT"
        ? this.$t(
            `Minimum amount is ${this.appConfigs.min_amount_for_withdraw_usd} USDT`
          )
        : this.withdrawAmountError;

    this.withdrawAddressError =
      this.withdrawAddress === "" ? this.$t("Address cannot be empty") : "";
    // this.withdrawSecurityCodeError = this.withdrawSecurityCode === '' ?
    //     this.$t('Finance password cannot be empty') : '';

    if (this.token === "DEXNET") {
      let response = await dispatchValidateDexnetAddress(
        this.$store,
        this.withdrawAddress
      );
      if (!response || response.status != 200) {
        this.withdrawAddressError = this.$t("Address is not valid");
      }
    }

    return !(
      this.withdrawAmountError ||
      this.withdrawAddressError ||
      this.withdrawSecurityCodeError
    );
  }

  public async handleWithdrawFunds() {
    if (await this.validateWithdrawForm()) {
      this.loadingState.submit = true;
      const data = {
        amount: this.withdrawAmount,
        standart: this.standart,
        token: this.token,
        wallet_address: this.withdrawAddress,
        security_code: this.withdrawSecurityCode,
      };
      let response = await dispatchSubmitWithdrawRequest(this.$store, data);

      if (response) {
        // this.withdrawRequestId = response.request_id;
        this.withdrawStep = 2;
        // @ts-ignore
        // this.$toast.success(this.$t('Withdraw request created successfully. We have sent you an email to confirm withdrawal'), {
        //   duration: 5000, position: 'bottom',
        // });
        // this.closeModal();
      } else {
        this.withdrawSecurityCodeError = response.security_code
          ? response.security_code[0]
          : "";
        if (response.wallet_address) {
          this.withdrawAddressError = response.wallet_address
            ? response.wallet_address[0]
            : "";
        } else if (response.non_field_errors) {
          this.withdrawAddressError = response.non_field_errors
            ? response.non_field_errors[0]
            : "";
        }
        this.withdrawAmountError = response.amount ? response.amount[0] : "";
        this.$toast.error(this.$t("Error").toString());
      }
      this.loadingState.submit = false;
    }
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit("close-modal", this.withdrawStep === 2);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
  get appConfigs() {
    return readAppConfigs(this.$store);
  }
}
