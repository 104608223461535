
import {
  dispatchGetTransactionsList,
  dispatchCheckDexfreedomTokenInfo,
} from "@/store/mlm/actions";
import { readTransactionsResponse } from "@/store/mlm/getters";
import WithdrawalModal from "@/components/Main/Transactions/WithdrawalModal.vue";
import DepositModal from "@/components/Main/Transactions/DepositModal.vue";
import GiveAGiftModal from "@/components/Main/Transactions/GiveAGiftModal.vue";
import TransactionsInfoModal from "@/components/Main/Transactions/TransactionsInfoModal.vue";
import TransferTokensModal from "@/components/Main/Transactions/TransferTokensModal.vue";
import { Component, Vue } from "vue-property-decorator";
import { dispatchGetAllConfigs } from "@/store/main/actions";

import moment from "moment-timezone";

@Component({
  components: {
    WithdrawalModal,
    DepositModal,
    GiveAGiftModal,
    TransactionsInfoModal,
    TransferTokensModal,
  },
})
export default class Transactions extends Vue {
  public page = 1;
  public searchCommentParams = "";
  public selectedPeriod = "";
  public selectedCurrency = "UNT";
  public selectedType = "";
  public selectedСommentType = "";
  public withdrawModalShow = false;
  public giftModalShow = false;
  public depositModalShow = false;
  public transactionsInfoModal = false;
  public transferTokensModalShow = false;

  public dexfreedomTokenInfo: {
    success: boolean;
    amount: number;
    last_transaction_dt: Date | null;
  } = {
    success: false,
    amount: 0,
    last_transaction_dt: null,
  };

  public async mounted() {
    dispatchGetAllConfigs(this.$store);
    this.handleUpdateList();
    if (this.$router.currentRoute.query.top_up) {
      this.handleOpenDepositModal();
    }

    let dexfreedomTokenInfoResp = await dispatchCheckDexfreedomTokenInfo(
      this.$store
    );
    if (
      !dexfreedomTokenInfoResp ||
      dexfreedomTokenInfoResp.status != 200 ||
      dexfreedomTokenInfoResp.data == null
    ) {
      console.log("Error check dexfreedom token info");
    } else {
      this.dexfreedomTokenInfo = {
        success: dexfreedomTokenInfoResp.data.success,
        amount: dexfreedomTokenInfoResp.data.amount,
        last_transaction_dt: dexfreedomTokenInfoResp.data.last_transaction_dt
          ? new Date(dexfreedomTokenInfoResp.data.last_transaction_dt)
          : null,
      };
    }
  }

  public handleOpenWithdrawModal() {
    this.withdrawModalShow = true;
  }

  public handleCloseWithdrawModal(updated: boolean = false) {
    this.withdrawModalShow = false;
    if (updated) {
      this.handleUpdateList();
    }
  }

  public handleOpenGiftModal() {
    this.giftModalShow = true;
  }

  public handleCloseGiftModal(updated: boolean = false) {
    this.giftModalShow = false;
    if (updated) {
      this.handleUpdateList();
    }
  }

  public handleOpenDepositModal() {
    this.depositModalShow = true;
  }

  public handleCloseDepositModal() {
    this.depositModalShow = false;
    this.handleUpdateList();
  }

  public handleChangeType(newType) {
    this.page = 1;
    this.selectedСommentType = newType;
    this.handleUpdateList();
  }

  public async handleChangePage(page) {
    if (this.page !== page) {
      this.page = page;
      this.handleUpdateList();
    }
  }

  public async handleUpdateList() {
    const rowQuery = `?page=${this.page}&page_size=${10}`;
    await dispatchGetTransactionsList(this.$store, {
      filter: {
        period: this.selectedPeriod,
        // currency: this.selectedCurrency,
        search_comment_params: this.searchCommentParams,
        comment_type: this.selectedСommentType,
      },
      rowQuery,
    });
  }

  get transactionsResponse() {
    return readTransactionsResponse(this.$store);
  }

  public async handleOpenTransferTokensModal() {
    console.log("transfer tokens", this.dexfreedomTokenInfo);
    this.transferTokensModalShow = true;
    console.log("modal", this.transferTokensModalShow);
  }
  public handleCloseTransferTokensModal() {
    this.transferTokensModalShow = false;
    this.handleUpdateList();
  }

  get isTransferButtonActive(): boolean {
    if (this.dexfreedomTokenInfo.amount === 0) {
      return false;
    }
    return this.durationUntilNextTransfer.asMilliseconds() <= 0;
  }

  get durationUntilNextTransfer() {
    const dubaiTimezone = "Europe/Kyiv";
    if (!this.dexfreedomTokenInfo.last_transaction_dt) {
      return moment.duration(0);
    }
    const deadline = moment
      .tz(this.dexfreedomTokenInfo.last_transaction_dt, dubaiTimezone)
      .add(30, "days");
    const now = moment.tz(dubaiTimezone);
    return moment.duration(deadline.diff(now));
  }

  get timeUntilTransfer() {
    const duration = this.durationUntilNextTransfer;
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return {
      days: days,
      hours: hours,
      minutes: minutes,
    };
  }
}
