var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"popup",class:{ popup_active: _vm.modalShow }},[_c('div',{staticClass:"popup__container popup__container_null_padding"},[_c('div',{staticClass:"popup__container-content"},[_c('button',{staticClass:"popup__exit",attrs:{"type":"button"},on:{"click":_vm.closeModal}}),_c('div',{staticClass:"popup__titleblock"},[_c('div',{staticClass:"popup__text"},[_c('h2',{staticClass:"popup__title"},[_vm._v(_vm._s(_vm.$t("Give alt")))]),_c('p',{staticClass:"popup__subtitle"},[_vm._v(_vm._s(_vm.$t("Select product")))])])]),_c('div',{staticClass:"popup__types-packages"},_vm._l((_vm.packages),function(pack){return _c('div',{key:`package-${pack.id}`,staticClass:"popup__item",class:_vm.selectedPackage && _vm.selectedPackage.id === pack.id
              ? 'popup__item_checked'
              : ''},[_c('label',{staticClass:"form-checkbox popup__item-checkbox",on:{"click":function($event){_vm.selectedPackage = pack}}},[_c('input',{staticClass:"form-checkbox__input",attrs:{"type":"radio"},domProps:{"checked":_vm.selectedPackage && _vm.selectedPackage.id === pack.id}}),_c('span',{staticClass:"form-checkbox__text"},[_vm._v(_vm._s(pack.name))])]),_c('p',{staticClass:"popup__item-price",class:{
              'color-red':
                _vm.userProfile &&
                Number(_vm.userProfile.balances.units_total) <
                  Number(
                    pack.discount_price
                      ? pack.discount_price
                      : pack.full_price
                  ),
              'color-blue':
                _vm.userProfile &&
                Number(_vm.userProfile.balances.units_total) >=
                  Number(
                    pack.discount_price
                      ? pack.discount_price
                      : pack.full_price
                  ),
            }},[_vm._v(" "+_vm._s(Number( pack.discount_price ? pack.discount_price : pack.full_price ).toFixed(0))+" USDT ")])])}),0),_c('p',{staticClass:"popup__present-text",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("A promotional code will be generated for the recipient..."))+" ")]),_c('div',{staticStyle:{"margin-bottom":"16px","display":"flex","gap":"16px"}},[_c('div',{staticStyle:{"width":"24px","height":"24px"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"cyan","width":"24px","height":"24px"}},[_c('path',{attrs:{"d":"M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"}})])]),_c('p',{staticStyle:{"color":"cyan"}},[_vm._v(_vm._s(_vm.$t("gift_modal.notice")))])]),(_vm.selectedPackage && !_vm.promocode)?_c('div',{staticClass:"popup__present-sum"},[_c('p',{staticClass:"popup__present-sum-title"},[_vm._v(" "+_vm._s(_vm.$t("Your account will be debited by"))+" ")]),_c('p',{staticClass:"popup__present-sum-val"},[_c('span',{staticClass:"popup__coast"},[_vm._v(_vm._s(Number(_vm.packPrice).toFixed(0)))]),_c('span',{staticClass:"popup__usd"},[_vm._v("USDT")])]),(
            _vm.selectedPackage &&
            _vm.userProfile &&
            Number(_vm.userProfile.balances.units_total) < Number(_vm.packPrice)
          )?_c('p',{staticClass:"popup__present-error"},[_vm._v(" "+_vm._s(_vm.$t("Deposit your account to make a transaction"))+" ")]):_vm._e()]):_vm._e(),(_vm.selectedPackage && !_vm.promocode)?_c('button',{staticClass:"popup__btn popup__present-btn",attrs:{"disabled":_vm.loadingState.submit,"type":"button"},on:{"click":_vm.handleCreatePromo}},[_vm._v(" "+_vm._s(_vm.$t("Create certificate"))+" ")]):_vm._e()]),(_vm.promocode)?_c('div',{staticClass:"popup__footer"},[_c('div',{staticClass:"popup__promo-success"},[_c('div',{staticClass:"popup__promo-icon"}),_c('p',{staticClass:"popup__promo-text"},[_vm._v(" "+_vm._s(_vm.$t("Certificate successfully generated"))+" ")])]),_c('div',{staticClass:"popup__promo-value"},[_c('input',{staticClass:"popup__promo-input",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.promocode}}),_c('button',{staticClass:"popup__promo-copy",attrs:{"type":"button","aria-label":_vm.$t('Copy')},on:{"click":function($event){return _vm.copyToClipboard(_vm.promocode)}}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }