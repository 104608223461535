
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class EmirexInfoModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  public isRestrictedSecected = true;

  public get countries() {
    return this.isRestrictedSecected
      ? this.$t("emirex_info.restricted.countries")
      : this.$t("emirex_info.allowed.countries");
  }

  public closeModal() {
    this.$emit("close-modal");
  }
}
