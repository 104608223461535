var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"modal",class:{ 'modal-active': _vm.modalShow },staticStyle:{"z-index":"999"}},[_c('div',{staticClass:"modal_container"},[_c('div',{staticClass:"modal_title-container"},[_c('h2',{staticClass:"modal_title"},[_vm._v(_vm._s(_vm.$t("emirex_info.title")))]),_c('button',{staticClass:"modal_close",attrs:{"type":"button"},on:{"click":_vm.closeModal}})]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"16px"}},[_c('p',[_vm._v(_vm._s(_vm.$t("emirex_info.info")))]),_c('ul',{staticStyle:{"display":"flex","flex-direction":"column","gap":"8px","list-style":"inside disc"}},_vm._l((_vm.$t('emirex_info.points')),function(point){return _c('li',{key:point,staticStyle:{"list-style":"inherit"}},[_vm._v(" "+_vm._s(point)+" ")])}),0),_c('div',[_c('p',{staticStyle:{"margin-bottom":"8px"}},[_vm._v(_vm._s(_vm.$t("emirex_info.countries")))]),_c('div',{staticStyle:{"display":"flex","gap":"16px","margin-bottom":"16px"}},[_c('button',{staticStyle:{"background-color":"transparent","font-weight":"600","color":"red","padding":"8px 16px","cursor":"pointer"},style:({
              borderBottom: _vm.isRestrictedSecected
                ? '1px solid #00fff0'
                : '1px solid transparent',
            }),attrs:{"type":"button"},on:{"click":function($event){_vm.isRestrictedSecected = true}}},[_vm._v(" "+_vm._s(_vm.$t("emirex_info.restricted.title"))+" ")]),_c('button',{staticStyle:{"background-color":"transparent","font-weight":"600","color":"green","padding":"8px 16px","cursor":"pointer"},style:({
              borderBottom: _vm.isRestrictedSecected
                ? '1px solid transparent'
                : '1px solid #00fff0',
            }),attrs:{"type":"button"},on:{"click":function($event){_vm.isRestrictedSecected = false}}},[_vm._v(" "+_vm._s(_vm.$t("emirex_info.allowed.title"))+" ")])]),_c('ul',{staticStyle:{"display":"flex","gap":"4px","flex-wrap":"wrap"}},_vm._l((_vm.countries),function(country){return _c('li',{key:country},[_vm._v(_vm._s(country)+",")])}),0)]),_c('h2',{staticClass:"modal_title"},[_vm._v(_vm._s(_vm.$t("emirex_info.camera.title")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("emirex_info.camera.text"))+" "),(_vm.$i18n.locale === 'en')?_c('a',{staticClass:"emirex_link",attrs:{"href":"https://support.sumsub.com/general-rules/resolving-camera-issues","target":"_blank"}},[_vm._v("FAQ")]):_c('a',{staticClass:"emirex_link",attrs:{"href":"/docs/emirex_camera_faq.pdf","target":"_blank"}},[_vm._v("FAQ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }