
import { IEmirexPaymentRequest, IInvoice } from "@/interfaces/mlm";
import {
  dispatchGetEmirexEuroRate,
  dispatchGetOrCreateOrderInvoice,
  dispatchGetPaymentStatus,
  dispatchRequestEmirexPayment,
} from "@/store/mlm/actions";
import { Component, Vue, Prop } from "vue-property-decorator";
import { dispatchGetUserProfile } from "@/store/main/actions";
import { readUserProfile } from "@/store/main/getters";
import { commitSetInvoiceResponse } from "@/store/mlm/mutations";
import { readInvoiceResponse } from "@/store/mlm/getters";
import EmirexInfoModal from "@/components/Main/Transactions/EmirexInfoModal.vue";

@Component({
  components: {
    EmirexInfoModal,
  },
})
export default class DepositModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  public loadingState: any = {
    standart: false,
  };
  public is_production: boolean = process.env.VUE_APP_ENV === "production";
  public emirex_link: string = "https://omnix.exchange";
  public emirexInfoModalShow: boolean = false;
  public emirexPaymentSuccess: boolean = false;
  public standart: string = "";
  public invoiceDetail: IInvoice | null = null;
  public transaction: any = null;
  public progressPercent = 100;
  public timeToReload = 15;
  public isModalClosed: boolean = false;
  public emirexUserData: IEmirexPaymentRequest = {
    user_name: "",
    amount: 0,
    amount_in_euro: 0,
    email: "",
  };
  public emirexPaymentData: any = {
    show: false,
    data: null,
    token: "",
  };
  public euroRate: any = 0;

  public async mounted() {
    commitSetInvoiceResponse(this.$store, null);
    await this.handleChangeMethod("BEP20");
    window.addEventListener("message", this.handleIframeMessage);
    this.updateProgressBar();
    if (this.userProfile) {
      this.emirexUserData = {
        user_name: this.userProfile.full_name ? this.userProfile.full_name : "",
        amount: 0,
        amount_in_euro: 0,
        email: this.userProfile.email ? this.userProfile.email : "",
      };
    }
  }

  public async handleChangeMethod(standart: string) {
    if (this.standart != standart) {
      this.loadingState.standart = true;
      this.standart = standart;
      if (this.standart === "EMIREX") {
        const response = await dispatchGetEmirexEuroRate(this.$store);
        this.euroRate = response ? response.usdt_euro_rate : 0;
      } else {
        await dispatchGetOrCreateOrderInvoice(this.$store, {
          order: null,
          standart: this.standart,
          token: "USDT",
        });
        if (this.invoiceResponse && this.invoiceResponse.invoice) {
          this.invoiceDetail = this.invoiceResponse.invoice;
        }
      }
      this.progressPercent = 100;
      this.timeToReload = 15;
      this.loadingState.standart = false;
    }
  }

  public refreshTimer() {
    this.progressPercent = 0;
    this.timeToReload = 0;
  }

  public updateAmountEuro() {
    if (this.euroRate && this.emirexUserData.amount) {
      this.emirexUserData.amount_in_euro = Number(
        (Number(this.emirexUserData.amount) * this.euroRate * 1.1).toFixed(2)
      );
    }
  }

  public async updateProgressBar() {
    if (this.isModalClosed) {
      return;
    }
    if (this.invoiceDetail && this.invoiceDetail.status === "WAIT_PAYMENT") {
      this.progressPercent = this.progressPercent - 6.66;
      this.timeToReload = this.timeToReload - 1;
      if (this.progressPercent > 0) {
        setTimeout(() => this.updateProgressBar(), 1000);
      } else {
        await this.updatePaymentInfo();
      }
    } else if (
      this.invoiceDetail &&
      this.invoiceDetail.status !== "WAIT_PAYMENT"
    ) {
      dispatchGetUserProfile(this.$store);
      // @ts-ignore
      this.$toast.success(this.$t("Order payment successful"), {
        duration: 3000,
        position: "bottom",
      });
      this.closeModal();
    }
  }

  public async updatePaymentInfo() {
    if (this.invoiceResponse && this.invoiceDetail) {
      const paymentResponse = await dispatchGetPaymentStatus(this.$store, {
        address: this.invoiceResponse.address,
        invoice: this.invoiceDetail.id,
      });
      if (paymentResponse) {
        this.invoiceDetail = paymentResponse.invoice;
        this.transaction = paymentResponse.transaction;
      }
      this.progressPercent = 100;
      this.timeToReload = 15;
      this.updateProgressBar();
    }
  }

  public async handleRequestEmirexPayment() {
    if (
      !this.emirexUserData.user_name ||
      !this.emirexUserData.email ||
      !this.emirexUserData.amount
    ) {
      // @ts-ignore
      this.$toast.error(this.$t("Please, fill all necessary fields"), {
        duration: 3000,
        position: "bottom",
      });
      return;
    }
    const response = await dispatchRequestEmirexPayment(
      this.$store,
      this.emirexUserData
    );
    if (response) {
      this.emirexPaymentData = {
        show: true,
        data: response.response,
        token: response.response.link_hash,
      };
    } else {
      // @ts-ignore
      this.$toast.error(this.$t("Something went wrong, try again later"), {
        duration: 3000,
        position: "bottom",
      });
    }
  }

  public handleIframeMessage(event) {
    if (event.origin === "https://pay.paylinkio.com") {
      if (event.data.type === "processed") {
        this.emirexPaymentSuccess = true;
        // @ts-ignore
        this.$toast.success(this.$t("Order payment successful"), {
          duration: 1000,
          position: "bottom",
        });
      }
    }

    if (event.origin === this.emirex_link) {
      console.log(event.data);
      if (event.data === "closeWidget") {
        this.emirexPaymentSuccess = true;
        this.emirexPaymentData = {
          show: false,
          data: null,
          token: "",
        };

        // @ts-ignore
        this.$toast.success(this.$t("Order payment successful"), {
          duration: 1000,
          position: "bottom",
        });
      }
    }
  }

  public handleToggleEmirexFaq() {
    this.emirexInfoModalShow = !this.emirexInfoModalShow;
  }

  public handleCloseEmirexModal() {
    this.emirexPaymentData = {
      show: false,
      data: null,
      token: "",
    };
    dispatchGetUserProfile(this.$store);
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit("close-modal");
    dispatchGetUserProfile(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get invoiceResponse() {
    return readInvoiceResponse(this.$store);
  }
}
